import {AxiosResponse} from "axios"
import {PromiseResponse} from "./http"

export interface FileUploadInterface {
  file_name: string
  file: string // base 64
}

export interface IconUploadInterface {
  icon: string
  icon_name: string
  icon_url?: string
}

export interface FilterRadioProps {
  value: string | boolean | null
  label: string
}

export interface LocalizationWrapper {
  [key: string]: string
}

type FilterType = "text" | "select" | "checkbox" | "radio" | "async_select" | "multiple_select" | "async" | "checkboxes"

type TableRowColor =
  | "inherit"
  | "default"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning"
  | undefined
type TableRowAlign = "inherit" | "left" | "center" | "right" | "justify" | undefined
type TableAction = {
  label?: ((item: any) => string | JSX.Element) | string
  href?: (item: any) => string
  startIcon?: string
  onClickAction?: (item: any) => void
  color?: TableRowColor
  onDisabled?: (item: any) => boolean
  disabled?: boolean
  target?: string
}

export type TableFilterAsyncSearch = {
  fetchUrl: (
    page: number,
    size: number,
    filterData?: any,
    hideLoadingProgressBar?: boolean,
    param?: object,
  ) => Promise<any>
  filteringColumnNameOnBackend: string
  labelNameToDisplayInAutocompleteItems: string
  keyNameToDisplayInAutocompleteItems: string
}

export interface FilterProps {
  title: string
  name: string
  type?: FilterType
  size?: number
  options?: any[]
  optionLabelName?: string
  optionValueName?: string
  reversedCheckboxValue?: boolean // Нужно в случаях например когда нужно показать не опубликованные товары (Отправлять на сервер значение чекбокса false),
  radio?: FilterRadioProps[]
  defaultValue?: string | boolean | null
  searchUrl?: any
  helperText?: string
  asyncFilter?: TableFilterAsyncSearch
}

export interface TableProps {
  rows: TableRowProps[]
  filter?: FilterProps[]
  filterByDate?: {
    title: string
    size?: number
    name: string
  }[]
  filterByDateTime?: {
    title: string
    size?: number
    name: string
  }[]
  filterByAsyncAutocomplete?: {
    title: string
    size?: number
    name: string
    option: {value: string; label: string}
    searchService: (query?: string | undefined) => PromiseResponse<any[]>
  }
  fetchUrl: (page: number, size: number, filterData?: object) => Promise<AxiosResponse>
  refresh?: any
  onUpdate?: (data: any) => void
  filterOpened?: boolean
  paginationItems?: number[]
  minWidth?: string
  getTableResults?: (result: any) => void
  disablePagination?: boolean
  param?: object
  checkboxSelecting?: boolean
  onCheckboxSelected?: (item: any, checked: boolean) => void
  checkedValidationFunction?: (item: any) => boolean
  onCheckboxSelectAll?: (items: any, checked: boolean) => void
  allCheckedValidationFunction?: (items: any) => boolean
  onChangePage?: () => void
  onChangeSize?: () => void
  getFilterObject?: (filterData: object) => void
}

export interface TableRowProps {
  title: any
  type?: string
  value?: ((item: any) => string | JSX.Element) | string
  action?: TableAction
  align?: TableRowAlign
  width?: number | string
  selectingKey?: string
  saveAction?: (item: any) => void
  deleteAction?: (item: any) => void
  addedItems?: any[]
  disableCheckBox?: boolean
  className?: string
}

export enum MimeTypesToExtensions {
  "application/vnd.ms-excel" = ".xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" = ".xlsx",
  "image/jpeg" = ".jpeg",
  "application/pdf" = ".pdf",
  "application/msword" = ".doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" = ".docx",
}
